import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/Card";
import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@mui/material/CircularProgress";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import { blue } from "@material-ui/core/colors";
import { GET_DRUG_UTILITY } from "../api";
import ResultPage from "../ResultPage";

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundImage: 'url("nereus-assets/img/bg/pattern1.png")',
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  iconWrapper: {
    backgroundColor: blue[100],
    color: theme.palette.primary.main,
  },
  actions: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
  primaryAction: {
    width: "100%",
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(0),
      width: "auto",
    },
  },
}));

const data = {
  drugIndicationsSummary: [
    "Uses Helps prevent sunburn. If used as directed with other sun protection measures ( see Directions ), decreases the risk of skin cancer and early skin aging caused by the sun.",
  ],
  icdCodes: ["Cough"],
  openaiResponse:
    "Yes, it is necessary to use fluticasone propionate for the ICD code summary 'Cough'. Fluticasone propionate is a corticosteroid medication commonly used to treat respiratory conditions such as asthma, allergies, and chronic obstructive pulmonary disease (COPD). Cough can be a symptom of these respiratory conditions, and fluticasone propionate can help reduce inflammation in the airways and alleviate coughing.",
  isDrugNecessary: true,
};

export default function Form(props) {
  const classes = useStyles();
  const [info, setInfo] = useState({ drug: "", icdCodes: "" });
  const [result, setResult] = useState({});
  const [openResult, setOpenResult] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInfo({ ...info, [name]: value });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const data = await GET_DRUG_UTILITY(info.drug, info.icdCodes);
      setResult(data);
      setOpenResult(true);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const content = {
    header: "MediCheck AI",
    description:
      "MediCheck AI uses GPT-4 to combine medical knowledge with natural language processing, helping insurers determine drug necessity and coverage decisions..",
    "primary-action": "Check Now",
    ...props.content,
  };

  return (
    <section className={classes.section}>
      {openResult ? (
        <Box py={10} display="flex" alignItems="center" justifyContent="center">
          <ResultPage data={result} setOpenResult={setOpenResult} />
        </Box>
      ) : (
        <Container maxWidth="sm">
          <Box py={16}>
            <Card variant="outlined">
              <CardContent>
                <Box display="flex" mt={3}>
                  <Box mx="auto">
                    <Avatar variant="rounded" className={classes.iconWrapper}>
                      <SmartToyIcon />
                    </Avatar>
                  </Box>
                </Box>
                <Box mt={2} px={4}>
                  <Typography
                    variant="h5"
                    component="h3"
                    align="center"
                    gutterBottom={true}
                  >
                    {content["header"]}
                  </Typography>
                  <Typography
                    variant="body2"
                    component="p"
                    color="textSecondary"
                    align="left"
                  >
                    {content["description"]}
                  </Typography>
                  <Box my={3}>
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            variant="outlined"
                            required
                            fullWidth
                            size="small"
                            name="drug"
                            label="Drug Name"
                            onChange={handleOnChange}
                            inputProps={{ required: true }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            variant="outlined"
                            required
                            fullWidth
                            size="small"
                            type="ICD_Code"
                            name="icdCodes"
                            label="Please input your ICD 10 code"
                            onChange={handleOnChange}
                            inputProps={{ required: true }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Box
                            alignItems="center"
                            justifyContent="space-between"
                            className={classes.actions}
                          >
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              size="large"
                              className={classes.primaryAction}
                            >
                              {loading ? (
                                <CircularProgress
                                  size={20}
                                  sx={{ color: "#fff" }}
                                />
                              ) : (
                                content["primary-action"]
                              )}
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </form>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Container>
      )}
    </section>
  );
}
