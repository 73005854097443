import { Box, IconButton, Button, Typography, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ResultPage = ({ data, setOpenResult }) => {
  const handleClose = () => {
    setOpenResult(false);
  };

  const fields = [
    {
      label: "Drug Indication Suammary",
      data: data.drugIndicationsSummary?.[0] || "No result",
    },
    { label: "ICD Codes", data: data.icdCodes?.[0] || "No result" },
    {
      label: "MediConCen AI Response",
      data: data.openaiResponse || "No result",
    },
    {
      label: "Medical necessity in Drug",
      data: data.isDrugNecessary ? "Yes" : "No",
    },
  ];

  return (
    <Box sx={{ p: 2, background: "#fff", width: { xs: "90%", lg: "50%" } }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5" sx={{ color: "#000" }}>
          Result Page
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box sx={{ maxHeight: 400, overflowY: "auto" }}>
        {fields.map((field, index) => {
          return (
            <TextField
              key={index}
              variant="standard"
              multiline
              label={field.label}
              defaultValue={field.data}
              inputProps={{ readOnly: true }}
              sx={{ my: 2, width: "100%" }}
            />
          );
        })}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Button variant="contained" color="primary" onClick={handleClose}>
          Close
        </Button>
      </Box>
    </Box>
  );
};

export default ResultPage;
