import React from 'react';

import HorizontalNav2 from '../components/horizontal-navs/HorizontalNav2';
import SignIn1 from '../components/sign-in/SignIn1';
import Footer2 from '../components/footers/Footer2';

const meta = {};

export default function Index() {
  return (
    <React.Fragment>
      <HorizontalNav2 content={null} />

      <SignIn1 content={null} />

      <Footer2
        content={{
          copy: '\u00a9 2023 MediConCen. All rights reserved.',
        }}
      />
    </React.Fragment>
  );
}

